/** @var {object} リクエスト(ajax) */
var req;
var req2;

/** @var {int} ここに入れた時刻を過ぎると出荷日繰越 */
var limit_hour = 12;

/**
 * 初期化
 */

// 2022-05-30のみ繰越の期限を15時に変更
var now = new Date();
var start_date_time = new Date(2022, (5-1), 30, 0, 0, 0);
var end_date_time = new Date(2022, (5-1), 30, 14, 59, 59);

if (now >= start_date_time && now <= end_date_time) {
    limit_hour = 15;
}

/**
 * 在庫・納期情報の取得（単一商品）
 *
 * @param {string} code
 * @param {int} num
 * @param {int} pref_cd
 *
 * @return {void}
 */
window.getStock = function(code, num, pref_cd) {
    if(hideDeliveryGuide('product')) {
        return false;
    }

    if (req) {
        req.abort();
    }

    if (!code) {
        code = $('')
    }

    $('#product-guide-stock #loading').show();
    $('#product-guide-stock .guide-info').hide();
    $('#product-guide-stock .guide-contact').hide();

    req = $.ajax({
        async: true,
		type: 'GET',
        url: '/api/get-stock',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        },
		dataType: 'json',
		data: {
			code: code,
			num: num,
			shipping: true,
			delivery: true,
			pref: pref_cd,
			// today: '2022-1-28', // テスト用
		},
		success: function(data) {
			console.log(data);

			if(data.stock === '◎' || data.stock === '●' || data.stock === '○') {
				$('#guide-stock').html('<span class="red">'+data.stock+'</span>');

				// 納期表示
				showDelivery('#product-guide-stock', data)

				// 在庫表示
				if(data.stock === '◎') {
					$('#product-guide-stock .guide-info .stock').html('<span class="red">在庫あり</span>');
				} else if(data.stock === '●' || data.stock === '○') {
					$('#product-guide-stock .guide-info .stock').html('<span class="orange">在庫僅少</span>');
				}

				$('#product-guide-stock .guide-info').show();
				$('#product-guide-stock .guide-contact').hide();

			} else if(data.stock === '▲') {
				$('#product-guide-stock .guide-info').hide();
				$('#product-guide-stock .guide-contact').show();
			} else {
				$('#product-guide-stock .guide-info').hide();
				$('#product-guide-stock .guide-contact').show();
			}

		},
		error: function(XMLHttpRequest, textStatus, errorThrown){
			console.log(textStatus);
		},
		complete: function(){
            $('#product-guide-stock #loading').hide();
		}
	});
}

/**
 * 在庫・納期、欠品情報の取得とhidden値設定（カートページ・注文確認ページ）
 *
 * @return {void}
 */
window.getCartStock = function() {
    if (req2) {
        req2.abort();
    }

    // カートデータ取得
    var cart_data = [];
    $('.cart_item_get_stock').each(function(){
        cart_data.push({
            code: $(this).data('code'),
            // num: 999,
            num: $(this).data('num'),
        });
    });

    if (!cart_data.length) {
        return false;
    }
    // console.log(JSON.stringify(cart_data));

    $('#cart-guide-stock .loading').show();
    $('#cart-guide-stock .guide-info').hide();
    $('#cart-guide-stock .guide-contact').hide();

    req2 = $.ajax({
        async: true,
        type: 'GET',
        url: '/api/get-cart-stock',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        },
        dataType: 'json',
        data: {
            pref: $('#delivery_pref').val(),
            cart: JSON.stringify(cart_data),
        },
    }).done(function(data) {
        console.log(data);

        // // 組立施工にチェックが入っているかの判定
        var deliConstFlg = false;
        // if ($('.cart-detail[data-code="DELI_CONST"]').length) {
        // 	deliConstFlg = true;
        // }

        // 欠品商品があれば確認画面のhidden値にセットする
        setMissingParts(data.missing_parts);

        // 納期・出荷日案内を停止、処理終了
        if (hideDeliveryGuide('cart')) {
            $('#cart-guide-stock .loading').hide();
            if (data.stock.indexOf('▲') !== -1) {
                hideDeliveryDate(); // 希望納期無効化
            }
            return false;
        }

        // 明細合計10万円以上、またはメーカー直送品が明細に存在する、または組立施工にチェックが入っている場合
        if (data.other_flg || data.cart_total >= 100000 || deliConstFlg) {
            $('#cart-guide-stock .loading').hide();
            $('#cart-guide-stock .guide-info').hide();
            $('#cart-guide-stock .guide-contact')
                .html('<p>現在の内容では、出荷日等のご案内が出来ません。<br>ご注文・お見積り依頼完了後にご案内させていただきます。</p>')
                .show();
            return false;
        }

        // ▲が含まれる場合
        if (data.stock.indexOf('▲') !== -1) {
            $('#cart-guide-stock .loading').hide();
            $('#cart-guide-stock .guide-info').hide();
            $('#cart-guide-stock .guide-contact')
                .html('<p>ご注文後、出荷日をお知らせ</p>')
                .show();
                hideDeliveryDate(); // 希望納期無効化¥

            return false;
        }

        // カート明細を評価しての在庫表示
        if (data.stock.indexOf('○') !== -1 || data.stock.indexOf('●') !== -1) {
            $('#cart-guide-stock .stock').html('<span class="orange">在庫僅少</span>');
        } else {
            $('#cart-guide-stock .stock').html('<span class="red">在庫あり</span>');
        }

        // 納期表示
        showDelivery('#cart-guide-stock', data);

        $('#cart-guide-stock .loading').hide();
        $('#cart-guide-stock .guide-info').show();
        $('#cart-guide-stock .guide-contact').hide();
    }).fail(function(xhr, textStatus, errorThrown) {
        // console.log("NG:" + textStatus);
    });
}

/**
 * 欠品商品データをinput hidden値にセット
 *
 * @param {string} missing_parts
 *
 * @return {void}
 */
function setMissingParts(missing_parts) {
    if ($('#missing_parts').length && missing_parts) {
        $('#missing_parts').val(missing_parts);
    }
}

/**
 * 時刻判定して納期を表示
 *
 * @param {string} parent_elem_name
 * @param {object} data
 */
function showDelivery(parent_elem_name, data) {
    // var now = new Date(2023, 2, 15, 13, 0, 0);
    var now = new Date();
    var now_date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    console.log(now_date);

    var base_data_arr = data.base.split('-');
    var limit_date = new Date(base_data_arr[0], base_data_arr[1]-1, base_data_arr[2]);
    var limit_str = limit_date.getFullYear()+'/'+(limit_date.getMonth()+1)+'/'+limit_date.getDate()+' '+limit_hour+':00:00';
    var limit = Date.parse(limit_str);
    var showed_shipping = '';

    if (now_date.getTime() === limit_date.getTime() && now.getTime() <= limit) {
        // 当日リミットタイム前
        $(parent_elem_name+' .base-date').text('本日');
    } else if (now_date.getTime() === limit_date.getTime()) {
        // 当日リミットタイム後
        $(parent_elem_name+' .base-date').text(formatDate(data.base2));
    } else {
        // その他
        $(parent_elem_name+' .base-date').text(formatDate(data.base));
    }

    if (now.getTime() <= limit) {
        // リミットタイム前
        showed_shipping = data.shipping1;
        $(parent_elem_name+' .guide-shipping').text(formatDate(showed_shipping));
        $(parent_elem_name+' .guide-delivery').text(formatDate(data.delivery1));
        $(parent_elem_name+' #limit-hour').text(limit_hour);
    } else {
        // リミットタイム後
        showed_shipping = data.shipping2;
        $(parent_elem_name+' .guide-shipping').text(formatDate(showed_shipping));
        $(parent_elem_name+' .guide-delivery').text(formatDate(data.delivery2));
        $(parent_elem_name+' #limit-hour').text(limit_hour);
    }

    // 表示する出荷日が土曜日以外の場合、土曜出荷の注意文を非表示
    var tmp_date = new Date(parseInt(showed_shipping.substr(0, 4)), parseInt(showed_shipping.substr(5, 2)) - 1, showed_shipping.substr(8, 2));

    // 2022-01-31に使用した臨時コード
    // if (tmp_date.getDay() !== 6) {
        // $('.saturday-caution').hide();
    // }

    if($('#showed_shipping').length) {
        $('#showed_shipping').val(showed_shipping);
    }
}

/**
 * 日付のフォーマット
 *
 * @param {string} day yyyy-mm-dd
 * @return {string} m月d日（w）
 */
function formatDate(day) {
    var result = day.split('-');
    var day_of_week_str_jp = [ "日", "月", "火", "水", "木", "金", "土" ]
    var date = new Date(result[0], result[1]-1, result[2]);
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var week = day_of_week_str_jp[date.getDay()];

    return String(month) + "月" + String(day) + '日（' + String(week) + '）';
}

/**
 * 指定期間では納期・出荷日案内を停止
 *
 * @param {string} type search|cart
 *
 * @return {bool}
 */
function hideDeliveryGuide(type) {
    var now = new Date();

    // 出荷日案内をOFFにする日時範囲の指定（※月の指定はマイナス1する）
    var start_date_time = new Date(2024, (8-1), 1, 1, 0, 0);   // 2024/8/1/1:00 start
	var end_date_time = new Date(2024, (8-1), 19, 1, 0, 0);    // 2024/8/19/1:00 end

    var elem_id = '#product-guide-stock';
    if(type === 'cart') {
        elem_id = '#cart-guide-stock';
    }

    if(now >= start_date_time && now <= end_date_time) {
        $(elem_id + ' .guide-info').hide();
        $(elem_id + ' .caution').hide();
        $(elem_id + ' .guide-contact').show()
            // .html('<p class="mb-1">お盆期間前後のため、<br>出荷日のお知らせ機能を停止しております。</p><p>※夏季休業前の出荷は受付終了しております。<br>現時点でご注文いただくと、お届けは夏季休業明けとなります。</p>');
            // .html('<p>お盆期間前後のため、<br>出荷日のお知らせ機能を停止しております</p>');
            // .html('<p>年末年始休暇期間前後のため、<br>出荷日のお知らせ機能を停止しております</p>');
            .html('<p>長期休業前後の為、<br>お届け予定日は非表示とさせて頂いております</p>');

        return true;
    }
    return false; // 現在日時が指定期間外の場合はfalseを返す
}
/**
 * 欠品商品データが含まれている場合希望納期を無効化
 *
 * @return {void}
 */
function hideDeliveryDate() {
    $("#delivery_date").prop("disabled", true);
    $('#delivery_date').css({'cssText': 'background-color: #e9ecef !important;'});
    $('.delivery_ex_text').hide();
    $('.shortage_text').show();
}
